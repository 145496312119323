module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HookActions","short_name":"Webhooks for developers.","start_url":"/","background_color":"#ffffff","theme_color":"#3664FA","display":"standalone","icons":[{"src":"static/android-chrome-192x192.png","sites":"192x192","type":"image/png"},{"src":"static/android-chrome-512x512.png","sites":"512x512","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
